"use client";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Link, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  covidImage,
  drugImage,
  dumbbellImage,
  healthChecklistImage,
  leavesImage,
  lungsImage,
  momImage,
  shieldImage,
  worldImage
} from "public/images/customIcons";

import { hriStory, vaccinationsStory } from "common/content/dataStories";
import { useIsMobile } from "common/util/hooks/useIsMobile";

import DataStoryCard from "common/components/Stories/DataStoryCard";
import { HighlightedTopicsGrid } from "common/components/TabbedMenu/HighlightedTopicsGrid";
import { TabbedMenu, TabMenuWithContent } from "common/components/TabbedMenu/TabbedMenu";

export const HomePageTabbedMenu = () => {
  const { palette } = useTheme();
  const isMobile = useIsMobile();

  const buttons: TabMenuWithContent[] = [
    {
      title: "Health Outcomes",
      description:
        "Discover information and data on a wide range of illnesses and diseases, covering prevention, symptoms, treatments, and current health trends.",
      content: (
        <HighlightedTopicsGrid
          items={[
            {
              title: "Chronic Disease",
              image: lungsImage,
              src: "/topics/chronic-disease/locations/state"
            },
            {
              title: "Infectious Disease",
              image: covidImage,
              src: "/topics/infectious-disease/locations/state"
            },
            {
              title: "Maternity & Child Health",
              image: momImage,
              src: "/topics/maternal-and-child-health/state"
            },
            {
              title: "Mental Health & Substance Use",
              image: drugImage,
              src: "/topics/mental-health-substance-use/locations/state"
            }
          ]}
        />
      )
    },
    {
      title: "Health Influences",
      description:
        "Explore information and data about environmental and social determinants of health.",
      content: (
        <HighlightedTopicsGrid
          items={[
            {
              title: "Climate & Health",
              image: worldImage,
              src: "/topics/climate-and-health/locations/state"
            },
            {
              title: "Community Safety",
              image: shieldImage,
              src: "/topics/community-safety/state"
            },
            {
              title: "Environment",
              image: leavesImage,
              src: "/locations/state/environment"
            },
            {
              title: "Health Services Utilization",
              image: healthChecklistImage,
              src: "/topics/health-services-utilization/state"
            },
            {
              title: "Healthy Lifestyles",
              image: dumbbellImage,
              src: "/topics/healthy-lifestyles/state"
            }
          ]}
        />
      )
    },
    {
      title: "Data Stories",
      description:
        "Explore data stories to gain insights into health trends, outcomes, and disparities in Delaware communities.",
      content: (
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={4}
          justifyContent="space-between"
          flex={1}
          flexGrow={0}
        >
          <DataStoryCard cardAsLink {...hriStory} variant="small" />
          <DataStoryCard cardAsLink {...vaccinationsStory} variant="small" />
          <Link
            href="/portals/stories"
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 2,
              flexGrow: 0,
              width: {
                xs: "100%",
                md: "min-content"
              },
              borderRadius: {
                xs: "5px",
                md: 0
              },
              backgroundColor: {
                xs: palette.brand.main,
                md: "transparent"
              },
              color: { xs: "white", md: palette.component.textColor },
              "&:hover": {
                backgroundColor: {
                  xs: palette.brand.light,
                  md: "transparent"
                },
                color: palette.component.textColor
              },
              "&:focus": {
                backgroundColor: {
                  xs: palette.brand.light,
                  md: "transparent"
                },
                color: palette.component.textColor
              }
            }}
          >
            {!isMobile && (
              <Stack sx={{ mx: 3, mt: "auto" }}>
                <Box
                  sx={{
                    width: "44px",
                    height: "44px",
                    borderRadius: "100%",
                    mx: "auto",
                    backgroundColor: palette.brand.main,
                    color: "white",
                    p: 1
                  }}
                >
                  <ArrowForwardIcon sx={{ width: "100%", height: "100%" }} />
                </Box>
              </Stack>
            )}
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                mb: { xs: "auto" },
                mt: { xs: "auto", md: 1 },
                color: "inherit",
                p: { xs: 1, md: 0 }
              }}
            >
              View more data stories
            </Typography>
          </Link>
        </Stack>
      )
    }
  ];

  return <TabbedMenu buttons={buttons} />;
};
