"use client";
import { useCallback, useMemo } from "react";
import Image from "next/image";
import { Stack } from "@mui/material";
import covidCardImage from "public/images/homepage/covidCard.png";
import dodCardImage from "public/images/homepage/dodCard.png";
import watermarImage from "public/images/homepage/Watermark.png";
import sandRulerImage from "public/images/hri/rulerSand.png";

import { DEFAULT_MARGIN_SMALL_SCREEN_STR } from "layout/configuration";
import { sendGaUserInteractionEvent } from "common/util/googleAnalytics";

import { TopicCard } from "layout/card/TopicCard";

interface ConfProps {
  title: string;
  description: string;
  image: StaticImageData;
  newTopic?: boolean;
  href: string;
}

const Card = ({ title, image, newTopic, href, description }: ConfProps) => {
  const goToTopic = useCallback(() => {
    sendGaUserInteractionEvent({
      category: "Card",
      action: "Topic card click",
      label: title,
      ui_location: "Homepage"
    });
  }, [title]);
  const MemorizedImage = useMemo(
    () => (
      <Image
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flex: 1,
          objectFit: "cover",
          maxHeight: "116px"
        }}
        src={image}
        alt="placeholder"
      />
    ),
    [image]
  );

  return (
    <TopicCard
      key={title}
      cardImage={MemorizedImage}
      newTopic={newTopic ?? false}
      title={title}
      className="no-underline"
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 1
      }}
      href={href}
      onClick={goToTopic}
    >
      {description}
    </TopicCard>
  );
};

export const HomePageTopicCards = () => {
  const configuration: ConfProps[] = useMemo(() => {
    return [
      {
        title: "COVID-19 Dashboard",
        description:
          "Check out the redesigned Delaware COVID-19 dashboard! This updated dashboard features a seasonal approach to understanding this disease. Data will be updated weekly on Wednesdays.",
        image: covidCardImage,
        href: "/topics/covid-19"
      },
      {
        title: "Drug Overdose Deaths",
        description:
          "Explore the latest data and trends on drug overdose deaths in our newly revamped dashboard. Stay informed about this critical issue in our community.",
        image: dodCardImage,
        href: "/topics/drug-overdose-deaths"
      },
      {
        title: "Heat Related Illness",
        description:
          "Explore DPH's first report published on heat-related illnesses. The findings in this five-year report show the burden of heat-related illnesses on the Delaware population and identify those most at risk.",
        image: sandRulerImage,
        href: "/data-stories/heat-related-illness",
        newTopic: true
      }
    ];
  }, []);

  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      gap={4}
      justifyContent="space-between"
      display="flex"
      px={{ xs: DEFAULT_MARGIN_SMALL_SCREEN_STR, md: 0 }}
      flex={1}
    >
      <Stack
        sx={{
          display: {
            xs: "hidden",
            md: "flex"
          },
          position: "absolute",
          top: "10px",
          left: "auto",
          right: "80px",
          bottom: "auto",
          overflow: "visible",
          zIndex: 0
        }}
      >
        <Image
          src={watermarImage}
          alt={"my healthy community heart logo"}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            width: "100%",
            objectFit: "cover",
            overflow: "visible"
          }}
        />
      </Stack>
      {configuration.map((props) => (
        <Card key={props.title} {...props} />
      ))}
    </Stack>
  );
};
